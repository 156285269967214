.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background-color: bisque;
  min-width: 100%;
  position: absolute;
  min-height: 100%;
  margin: 0;
}
.App img{
  width: 20%;
}
